import './App.css';
import contructImage from './under-maintenance2.gif'

function Test(){
    return(
    <div classname="test" style={{height: "100vh", width:"100vw"}}>
        <h2 >This Website Is Under Construction !!!</h2>
            <h3>We have been making something <b>wonderful</b> back here.</h3>
        <img src={contructImage} style={{ backgroundColor:"black" }} alt="Contruction"/>
    </div>
    );
}

export default Test
import './App.css';
import Test from './test';

function App() {
  return (
    <div className="App">
      <Test/>
    </div>
  );
}

export default App;
